import * as ActionType from "../contants/AuthContanst";
import { TOKEN, DOMAIN_AIRPAYONE } from "../settings/config";

const initialState = {
  authLogin: null,
  isLoading: false,
  errorLogin: null,
  error: null,
  listUser: null,
  walletAddress: null,
  transationHistory: null,
  listAffiliates: null,
  userSwap: null,
  userProductSanPham: null,
  listGetPrice: null,
  investmentHistory: null,
  userRefund: null,
  userWithdraw: null,
  ReceiveAddress: null,
  resStaking: null,
  Error401: null,
  feeObject: null,
  Fee:null,
};

const authManagementReducer = (
  state = initialState,
  { type, payload, error }: any
) => {
  switch (type) {
    // _login
    case ActionType.LIST_USER_REQUEST:
      state.listUser = payload;
      state.isLoading = true;
      state.error = null;

      return { ...state };
    case ActionType.LOGIN_SUCCESS:
      state.authLogin = payload;
      state.isLoading = false;
      state.errorLogin = null;
      return { ...state };
    // end _login

    //  get dashboard
    case ActionType.LIST_USER_SUCCESS:
      state.listUser = payload;
      state.isLoading = false;
      state.error = null;
      state.Error401 = null;
      return { ...state };
    case ActionType.LIST_USER_FAILED:
      state.Error401 = error;
      return { ...state };

    //  end get dashboard

    //  get wallet
    case ActionType.ReceiveAddress_WALLET_SUCCESS:
      state.walletAddress = payload;
      state.ReceiveAddress = payload;
      state.isLoading = false;
      state.error = null;
      state.Error401 = null;
      return { ...state };
    case ActionType.ReceiveAddress_ALLET_FAILED:
      state.Error401 = error;
      return { ...state };
    //  end get wallet

    // start lich su dau tu

    case ActionType.LICHSUDAUTU_REQUEST:
      state.transationHistory = payload;
      state.isLoading = true;
      state.error = null;

      return { ...state };

    case ActionType.LICHSUDAUTU_SUCCESS:
      state.transationHistory = payload;
      state.isLoading = false;
      state.error = null;
      state.Error401 = null;
      return { ...state };
    case ActionType.LICHSUDAUTU_FAILED:
      state.Error401 = error;
      return { ...state };
    // end lich su dau tu

    // start affiliates
    case ActionType.LIST_AFFILIATED_SUCCESS:
      state.listAffiliates = payload;
      state.isLoading = false;
      state.error = null;
      state.Error401 = null;
      return { ...state };
    case ActionType.LIST_AFFILIATED_FAILED:
      state.Error401 = error;
      return { ...state };
    // end affiliates

    // start swap
    case ActionType.SWAP_SUCCESS:
      state.userSwap = payload;
      state.isLoading = false;
      state.error = null;
      state.Error401 = null;
      return { ...state };
    case ActionType.SWAP_FAILED:
      state.Error401 = error;
      return { ...state };
    // end swap

    // start product san pham
    case ActionType.PRODUCT_SP_SUCCESS:
      state.userProductSanPham = payload;
      state.resStaking = payload;
      state.isLoading = false;
      state.error = null;

      return { ...state };
    // end product san pham

    // start get price
    case ActionType.LIST_PRICETOKEN_SUCCESS:
      state.listGetPrice = payload;
      state.isLoading = false;
      state.error = null;

      return { ...state };
    // start get price

    // start lich su dau tu
    case ActionType.INVESTMENT_SUCCESS:
      state.investmentHistory = payload;
      state.isLoading = false;
      state.error = null;

      return { ...state };
    // end lich su dau tu

    // start refund
    case ActionType.REFUND_SUCCESS:
      state.userRefund = payload;
      state.isLoading = false;
      state.error = null;

      return { ...state };
    // start refund

    // start withdraw
    case ActionType.WITHDRAW_SUCCESS:
      state.userWithdraw = payload;
      state.isLoading = false;
      state.error = null;

      return { ...state };
    case ActionType.WITHDRAW_FEE_SUCCESS:
      state.feeObject = payload;
      state.isLoading = false;
      state.error = null;

      return { ...state };
    // end withdraw
    //get fee
    case ActionType.GET_FEE_SUCCESS:
      state.Fee = payload;
      state.isLoading = false;
      state.error = null;
      return { ...state };
    default:
      return state;
  }
};

export default authManagementReducer;
