export const ArrClone = [
  {
    title: "Dashboard",
    href: "/dashboard",
  },
  {
    title: "Swap",
    href: "/swap",
  },
  {
    title: "Affiliates",
    href: "/affiliates",
  },
  {
    title: "Staking",
    href: "/staking",
  },
  {
    title: "Withdraw",
    href: "/withdraw",
  },
  {
    title: "History",
    href: "/history",
  },

  {
    title: "Docs",
    href: "/docs",
  },
];
