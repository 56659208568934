import React, { useMemo, useContext, useEffect } from "react";
import { ConnectWallet } from "../Connect";
import { useActiveWeb3React, useLogout, useLogin } from "../../hooks";
import useGetPrice from "./useGetPrice";

import { ButtonConect } from "./styledMenu";
import { ModalConfirmContext } from "../ProviderPopUp/confirm";

const Connect = () => {
  const { account } = useActiveWeb3React();
  // useLogin();
  // useLogout();
  // useGetPrice();
  const { onOpen } = useContext(ModalConfirmContext)!;
  const customAccount = useMemo<any>(() => {
    if (account) {
      return `${account.substr(0, 4)}...${account.substr(account.length - 4)}`;
    }
    return `Connect Wallet`;
  }, [account]);

  return (
    <React.StrictMode>
      <ButtonConect onClick={() => onOpen(<ConnectWallet />)}>
        {customAccount}
      </ButtonConect>
    </React.StrictMode>
  );
};

export default Connect;
