import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Flex, Text } from "@raca2022/uikit";
import { ArrowRightModalIcon } from "../Svg";

export default function WalletItem(props) {
  return (
    <div className={`flex items-center ${props.className}`} onClick={props.onClick}>
      <Flex justifyContent="space-between" alignItems="center" className="wallet-flex" px='14px'>
        <Flex>
          <Box pr="14px" className="wallet-icon">{props.icon}</Box>
          <Text
            fontFamily='PlayRegular'
            fontSize="16px"
            fontWeight="500"
            lineHeight="22px"
            className="item-text"
            href=""
            onClick={(e) => e.preventDefault()}
          >
            {props.title}
          </Text>
        </Flex>
        <ArrowRightModalIcon className="cus-arrow" />
      </Flex>
    </div>
  );
}

WalletItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
