import React, { lazy, Suspense, useEffect } from "react";
import {
  Switch,
  Router,
  // useLocation,
  Route,
  // BrowserRouter,
} from "react-router-dom";
import { ResetCSS, Box } from "@raca2022/uikit";
import AOS from "aos";
import { ScrollToTop } from "../components/ScrollTop";
import { Menu } from "../components/Menu";
import Footer from "../components/FooterAirPayOne";
import Loading from "../components/Loading/LoadingDesktop";

import history from "../routerHistory";
import GlobalStyle from "../style";
import "aos/dist/aos.css";
import { TOKEN } from "../redux/settings/config";
import { Toaster } from "react-hot-toast";
import { LoginProvider } from "../components/Provider/LoginProvider";

//
const Home = lazy(() => import("./Home"));
const Dashboard = lazy(() => import("./Dashboard"));
const Investment = lazy(() => import("./Investment"));
const History = lazy(() => import("./History"));
const Affiliates = lazy(() => import("./affiliates"));
const Swap = lazy(() => import("./Swap"));
const Withdraw = lazy(() => import("./Withdraw"));
const Farm = lazy(() => import("./Farm"));

const App = () => {
  useEffect(() => {
    AOS.init();
    // return localStorage.removeItem(TOKEN);
  }, []);
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          error: {
            duration: 1500,
          },
        }}
      />
      <Suspense fallback={<Loading />}>
        <Router history={history}>
          {/* @ts-ignore */}
          <ResetCSS />
          {/* @ts-ignore */}
          <GlobalStyle />
          <Menu />
          {/* <Social /> */}
          <Box className="height-content overFlow cus-pt-global" overflowX="hidden">
            <ScrollToTop>
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/dashboard" exact>
                  <Box className="bg dashboard-bg">
                    <Dashboard />
                  </Box>
                </Route>
                <Route path="/swap" exact>
                  <Box className="bg swap-bg">
                    <Swap />
                  </Box>
                </Route>
                <Route path="/history">
                  <Box className="bg history-bg">
                    <History />
                  </Box>
                </Route>
                <Route path="/staking" exact>
                  <Box className="bg staking-bg">
                    <Investment />
                  </Box>
                </Route>
                <Route path="/affiliates" exact>
                  <Box className="bg affiliate-bg">
                    <Affiliates />
                  </Box>
                </Route>
                <Route path="/withdraw" exact>
                  <Box className="bg withdraw-bg">
                    <Withdraw />
                  </Box>
                </Route>
                <Route path="/farm" exact>
                  <Box className="nd bg farm-bg">
                    <Farm />
                  </Box>
                </Route>
              </Switch>
            </ScrollToTop>
          </Box>
          <Footer />
        </Router>
      </Suspense>
    </>
  );
};

export default App;
