import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const LogoIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      id="logoAirPayOne"
      width="34"
      height="30"
      viewBox="0 0 34 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath ="url(#clip0_587_76524)">
        <path
          id="Vector"
          d="M19.0693 1.53649L33.1341 25.5211C33.1341 25.5211 35.5453 29.9923 32.3291 29.9923C29.1129 29.9923 22.4944 29.9923 22.4944 29.9923C22.4944 29.9923 21.2793 30.1959 20.4781 28.5711C19.6693 26.9424 15.2493 18.4072 15.2493 18.4072C15.2493 18.4072 13.9241 16.1908 15.2493 14.8809C16.8252 13.3252 16.5442 13.233 17.6605 15.1537C18.8491 17.1972 22.8855 24.096 22.8855 24.096C22.8855 24.096 23.288 25.3137 24.4917 25.3137C25.6954 25.3137 26.9029 25.3137 26.9029 25.3137C26.9029 25.3137 27.7079 25.0602 26.9029 23.6889C25.8017 21.8067 18.063 7.83611 18.063 7.83611C18.063 7.83611 17.258 6.21127 16.0543 8.24328C14.8506 10.2753 7.21448 23.2817 7.21448 23.2817C7.21448 23.2817 5.60827 25.3137 8.01949 25.3137C10.07 25.3137 9.97883 25.4059 10.4231 24.9334C10.4421 24.9104 12.0369 22.4712 12.0369 22.4712C12.0369 22.4712 12.709 21.8374 13.6431 22.8784C15.4582 24.895 15.2493 25.3175 14.4481 27.3496C14.1747 28.0448 13.8861 28.5749 13.6279 28.9706C13.2102 29.6159 12.4964 30 11.7331 30H2.28574C2.28574 30 -1.62916 30.1959 0.78205 25.7247C3.19326 21.2535 14.7861 1.53649 14.7861 1.53649C14.7861 1.53649 16.8593 -1.92061 19.0693 1.53649Z"
          fill="url(#paint0_linear_587_76524)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_587_76524"
          x1="17.5087"
          y1="0.860005"
          x2="16.9613"
          y2="29.7191"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D3FF" />
          <stop offset="1" stop-color="#008AFF" />
        </linearGradient>
        <clipPath id="clip0_587_76524">
          <rect width="34" height="30" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default LogoIcon;
