import styled from "styled-components";
import { Box, Text, Flex } from "@raca2022/uikit";

export const BoxModal = styled(Box)`
  position: relative;
  width: 410px;
  margin: 0 auto;
  padding: 10px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  background-color: #010819;
  border-radius: 16px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    margin: -2px;
    background-image: linear-gradient(
      265.98deg,
      #004eff -2.85%,
      #6bd0ff 101.88%
    );
    border-radius: 16px;
  }

  .cus-box-header {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .none-pb {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  @media only screen and (max-width: 743px) {
    width: 100%;
  }
`;

export const BoxPd = styled(Box)`
  padding-top: 0;
  position: relative;
`;

export const BoxPdDisconnect = styled(Box)`
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  z-index: 11;

  :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .text-custom {
    font-family: Monda !important;
    color: #fff;
    font-weight: 600;
    line-height: 1.5;
    font-size: 16px;
    background-color: transparent;
    padding: 8px;
    border-radius: 10px;
    display: flex;
    gap: 14px;
    cursor: pointer;
    margin-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    position: relative;
    height: 62px;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

export const BoxModalContent = styled(Box)`
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  & > * {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .wallet-flex {
    transition: 0.3s ease-in-out;
    padding: 5px 8px;
    width: 100%;
    border: 1px solid #004EFF;
    border-radius: 8px;
    background: transparent;
    transition: 0.3s ease;
    min-height: 52px;
    height: 100%;
    cursor: pointer;
    .cus-arrow {
      width: 9px;
      height: 16px;
      opacity: 0;
      visibility: hidden;
      margin-right: 15px;
    }
    :hover {
      background: #032980;
      border: none;
      .cus-arrow {
        opacity: 1;
        visibility: visible;
      }
    }
    .wallet-icon {
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  @media only screen and (max-width: 570px) {
    & > * {
      width: 100%;
    }
    .wallet-flex {
      width: 100%;
    }
  }
`;

export const BoxModalDisConnect = styled(Box)`
  .btn-connect {
    margin: 0 20px;
    width: 165px;
    height: 50px;
    background: linear-gradient(267.66deg, rgb(200, 255, 77) 0.33%, rgb(154, 222, 0) 98.08%);
    border: 2px solid rgb(229, 255, 171);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: none;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 110%;

    :hover {
      background: #ea6e6e;
      color: #671b1b;
    }
  }

  .noti-out-text {
    font-weight: 500;
    text-align: center;
    font-size: 22px;
    color: #fffdfd;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 570px) {
    min-width: auto;
  }
`;

export const ModalText = styled(Text)`
  font-family: PlayRegular;
  font-size: 20px;
  line-height: 31px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-transform: uppercase;
`;

export const BoxHeader = styled(Flex)`
  padding: 44px 16px 24px 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  .BoxImgPopUpDisConnect{
    width: 161px;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 100%;
    }
  }
  button {
    border: none;
    outline: none;
    background-color: transparent;
    color: #000;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    top: 14px;
    right: 12px;
    width: 36px;
    height: 26px;
    svg {
      width: 24px;
      height: 24px;
    }
    @media only screen and (max-width: 743px) {
      width: 11.25vw;
      height: 8.125vw;
      svg {
        width: 7.5vw;
        height: 7.5vw;
      }
    }
  }

  @media only screen and (max-width: 570px) {
    padding: 12px;
  }
`;

export const ConnectWalletContainer = styled.div`
  @media only screen and (max-width: 743px) {
    width: 100%;
  }
`
