import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  bsc,
  injected,
  walletconnect,
  walletconnect1,
} from "../utils/connector";

export default function useConnectWallet() {
  const { connector, account, activate, deactivate, active }: any =
    useWeb3React();
  const [currentConnector, setCurrentConnector]: any = useState();
  const [currentConnectorId, setCurrentConnectorId] = useState();

  useEffect(() => {
    if (
      currentConnectorId &&
      currentConnector &&
      currentConnector === connector
    ) {
      // Activated
      if (account) {
        localStorage.setItem("accountStatus", "1");
        localStorage.setItem("connectorId", currentConnectorId);
        // if (window.ethereum && window.ethereum.isMetaMask) {
        //   window.ethereum.request({ method: "wallet_switchEthereumChain" });
        // }
        if (window.ethereum && window.ethereum.isMetaMask) {
          window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "BNB Chain Mainnet",
                chainId: "0x38",
                rpcUrls: ["https://bsc-dataseed.binance.org"],
                nativeCurrency: {
                  name: "BNB",
                  symbol: "BNB",
                  decimals: 18,
                },
              },
            ],
          });
        }
      }
    }
  }, [account, currentConnectorId, currentConnector, connector]);
  const [tried, setTried] = useState(false);

  async function walletLogin(connectorId: any) {
    let _connector;

    switch (connectorId) {
      case "walletconnect":
        _connector = walletconnect1;
        break;
      case "bsc":
        _connector = bsc;
        break;
      default:
        // injected
        _connector = injected;
        break;
    }
    console.log(connectorId)
    console.log(_connector)
    setCurrentConnectorId(connectorId);
    setCurrentConnector(_connector);
    !active && (await activate(_connector));
  }

  function walletLogout() {
    deactivate();
    window.localStorage.removeItem("accountStatus");
    window.localStorage.removeItem("connectorId");
    window.localStorage.removeItem("walletconnect");
  }

  return { walletLogin, walletLogout };
}
