import React, { useMemo, useState, useEffect } from "react";
import {
  Flex,
  Box,
  Text,
  useMatchBreakpoints,
  IconButton,
} from "@raca2022/uikit";
import { useMenuContext } from "./context/Context";
import Logo from "./Logo";
import MenuList, { MenuListMobile } from "./MenuList";
import Connect from "./ButtonConnect";
import { OpenMenu } from "../Svg";

import {
  MenuWrapper,
  ButtonConect,
  WrapperMobile,
  BoxMenu,
  MenuLangdingWapper,
} from "./styledMenu";
import { Link, useLocation } from "react-router-dom";

const Menu = () => {
  const { isLg } = useMatchBreakpoints();
  const { openSidebar }: any = useMenuContext();
  const pathname = useLocation().pathname;
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const optionMemu = useMemo<any>(() => {
    if (!isLg) {
      return (
        <BoxMenu>
          <MenuList />
          <Connect />
        </BoxMenu>
      );
    }else{
      return (
        <Flex>
          <WrapperMobile>
            <IconButton
              className="bt-mobile"
              onClick={() => openSidebar()}
              startIcon={<OpenMenu width="32px" />}
            />
            <Connect />
          </WrapperMobile>
        </Flex>
      );
    }
  }, [isLg]);

  return (
    <React.StrictMode>
      {pathname === "/" ? (
        <MenuLangdingWapper isScroll={scrollPosition >= 150} >
          <React.StrictMode>
            <Logo href="/" />
          </React.StrictMode>
          <Link to="/dashboard">
            <button>Launch App</button>
          </Link>
        </MenuLangdingWapper>
      ) : (
        <>
          <MenuWrapper isScroll={scrollPosition >= 50}>
            <React.StrictMode>
              <Logo href="/" />
            </React.StrictMode>
            <Box className="custom-menu">{optionMemu}</Box>
          </MenuWrapper>
          <MenuListMobile />
        </>
      )}
    </React.StrictMode>
  );
};

export default Menu;
