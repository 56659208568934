import React, { useEffect, useState, useMemo, useContext } from "react";
import { useWeb3React } from "@web3-react/core";
import { useConnectWallet, useActiveWeb3React } from "../../hooks";
import { NEED_A_PLACEHOLDER, WALLET_LIST } from "../../constants/index";
import WalletItem from "./WalletItem";
import { ModalProps } from "./config";
import { CloseConnectIcon, LogoAirPayOne } from "../Svg";
import {
  BoxModal,
  BoxModalContent,
  BoxPd,
  ModalText,
  BoxHeader,
  BoxModalDisConnect,
  BoxPdDisconnect,
  ConnectWalletContainer,
} from "./styled";
import { Box, Flex, useMatchBreakpoints } from "@raca2022/uikit";

import { ButtonSwap } from "../../view/Swap/styled";
import { BoxLogo, ModalConfirmContext } from "../ProviderPopUp/confirm";
import changeNetwork from "./funcChanngeNetword";

declare const window: Window & typeof globalThis & { ethereum: any };

const ConnectWallet: React.FC<ModalProps> = ({
  title,
  onDismiss,
  onBack,
  children,
  hideCloseButton = false,
  bodyPadding = "24px",
  headerBackground = "transparent",
  minWidth = "320px",
  ...props
}) => {
  const { isMobileL } = useMatchBreakpoints();
  const { account } = useActiveWeb3React();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { walletLogin, walletLogout } = useConnectWallet();
  const chainId = process.env.REACT_APP_CHAIN_ID ?? "0";
  const walletItemClass = "w-1/3 flex-auto mb-0.5 p-0.5 last:bg-transparent";
  const context = useWeb3React();
  const { active, error } = context;
  const { onClose } = useContext(ModalConfirmContext)!;

  if (window.ethereum) {
    window.ethereum.on("chainChanged", (chainId: any) => {
      // window.location.reload();
      localStorage.setItem("isCheck", "2");
    });

    window.ethereum.on("accountsChanged", (chainId: string) => {
      // window.location.reload();
      localStorage.setItem("isCheck", "2");
    });
  }

  // --- ACTION IN MODAL ---
  const showModalConnect = () => {
    setIsModalVisible(true);
  };
  const handleConnectOk = () => {
    setIsModalVisible(false);
  };
  const handleConnectCancel = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  //  --- Return address wallet with substring ---
  const accountEllipsis = active
    ? `${account?.substring(0, 4)}...${account?.substring(account.length - 4)}`
    : "Connect Wallet";

  // ---- HANDLE DISCONNECT ----
  const handleDisconnect = () => {
    walletLogout();
    setIsModalVisible(false);
    onClose?.();
  };

  // ---- HANDLE CONNECT ----
  const handleConnect = async (connectorId: string) => {
    try {
      const rs = await walletLogin(connectorId);
      setIsModalVisible(false);
      localStorage.setItem("isCheck", "1");
      onClose?.();
    } catch (e) {
      console.error("Login failed");
    }
  };
  useEffect(() => {
    // if (account) {
      if (window.ethereum) {
        if (chainId != window.ethereum.networkVersion) {
          changeNetwork(`0x${parseInt(chainId).toString(16)}`);
        }
      }
    // }
  }, [account]);

  const accountLog = useMemo(() => {
    if (!isMobileL && account) {
      return (
        <>
          {account.substr(0, 10)}...{account.substr(account.length - 17)}
        </>
      );
    }
    if (isMobileL && account) {
      return (
        <>
          {account.substr(0, 5)}...{account.substr(account.length - 10)}
        </>
      );
    }
  }, [account]);
  return (
    <ConnectWalletContainer>
      <BoxModal>
        <>
          {!active ? (
            <BoxHeader>
              <Flex
                alignItems="flex-start"
                width="100%"
                justifyContent="flex-end"
              >
                <Box>
                  <button onClick={onClose}>
                    <CloseConnectIcon />
                  </button>
                </Box>
              </Flex>
              <BoxLogo>
                <LogoAirPayOne />
                <span>AirPayOne</span>
              </BoxLogo>

              <ModalText>Connect Wallet</ModalText>
            </BoxHeader>
          ) : (
            <BoxHeader className="none-pb">
              <Flex
                alignItems="flex-start"
                width="100%"
                justifyContent="flex-end"
              >
                <Box>
                  <button onClick={onClose}>
                    <CloseConnectIcon />
                  </button>
                </Box>
              </Flex>

              <BoxLogo>
                <LogoAirPayOne />
                <span>AirPayOne</span>
              </BoxLogo>
            </BoxHeader>
          )}
        </>
        {!active ? (
          <BoxPd>
            <BoxModalContent>
              {WALLET_LIST.map((wallet) => {
                if (
                  wallet.title === "Metamask" &&
                  !window?.ethereum &&
                  !window?.ethereum?.isMetaMask
                ) {
                  return (
                      <WalletItem
                        className={`cus-wallet`}
                        onClick={() => {window.location.href = `https://metamask.app.link/${window.location.hostname}${window.location.pathname}`}}
                        key={wallet.title}
                        icon={<wallet.icon width="32px" />}
                        title={wallet.title}
                      />
                  );
                } else {
                  return (
                    <WalletItem
                      className={`cus-wallet`}
                      key={wallet.title}
                      onClick={() => {
                        handleConnect(wallet.connectorId);
                      }}
                      icon={<wallet.icon width="32px" />}
                      title={wallet.title}
                    />
                  );
                }
              })}
              {NEED_A_PLACEHOLDER && <div className={walletItemClass} />}
            </BoxModalContent>
          </BoxPd>
        ) : (
          <BoxPdDisconnect>
            <BoxModalDisConnect>
              <div className="noti-out-button">
                <ButtonSwap
                  onClick={() => {
                    handleDisconnect();
                  }}
                >
                  Disconnect
                </ButtonSwap>
              </div>
            </BoxModalDisConnect>
          </BoxPdDisconnect>
        )}
      </BoxModal>
    </ConnectWalletContainer>
  );
};

export default React.memo(ConnectWallet);
