import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const AutorenewIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 21 18" {...props}>
      <g clipPath="url(#clip0_22_31100)">
        <path
          d="M10.84 4.5V6.75L14.2268 3.75L10.84 0.75V3C7.09759 3 4.06641 5.685 4.06641 9C4.06641 10.1775 4.45589 11.2725 5.11631 12.195L6.3525 11.1C5.97148 10.4775 5.75981 9.7575 5.75981 9C5.75981 6.5175 8.03743 4.5 10.84 4.5ZM16.5637 5.805L15.3275 6.9C15.7001 7.53 15.9202 8.2425 15.9202 9C15.9202 11.4825 13.6426 13.5 10.84 13.5V11.25L7.45321 14.25L10.84 17.25V15C14.5824 15 17.6136 12.315 17.6136 9C17.6136 7.8225 17.2241 6.7275 16.5637 5.805V5.805Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_31100">
          <rect width="20.3208" height="18" fill="white" transform="translate(0.679688)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default AutorenewIcon;
