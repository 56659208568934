import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const OpenMenu: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 32 32" fill="#fff" {...props}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_269_51390"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_269_51390)"></g>
        <path
          d="M3 25.6667H17.9333M3 16.3333H29.1333M14.2 7H29.1333"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Svg>
  );
};

export default OpenMenu;
