import React, { memo, useState } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { Flex, Box, Text, IconButton } from "@raca2022/uikit";
import Connect from "./../ButtonConnect";
import debounce from "lodash.debounce";

import { useMenuContext } from "../context/Context";
import {
  OpenMenu,
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
  CloseConnectIcon,
  DiscordIcon,
} from "../../Svg";

import { ArrClone } from "./config";

const MobileLogo = memo(() => {
  return (
    <React.StrictMode>
      <Flex height={"33px"}>
        <Link to="/">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Logo_temp"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M38.4874 21.2422C37.8382 22.2178 37.4887 23.4275 37.5761 24.7153C37.7633 27.408 39.8731 29.5804 42.4572 29.7495C45.5782 29.9446 48.1498 27.2649 47.975 24.0129C47.8377 21.5284 46.0525 19.4211 43.6931 18.9788C42.9815 18.8487 42.2824 18.8747 41.6333 19.0178L31.2718 0H19.0627L18.8879 3.73332H29.4866L38.4874 21.2422ZM14.3438 14.504C15.3675 14.5951 16.4161 14.3739 17.3774 13.8016C19.8741 12.3056 20.7355 8.98858 19.2999 6.38696C17.8642 3.78535 14.6809 2.88779 12.1841 4.38372C9.68739 5.87965 8.82601 9.19671 10.2616 11.7983C10.4614 12.1495 10.6861 12.4747 10.9358 12.7609L0 31.8178L10.786 43.6161L13.4824 40.8063L4.6814 31.2584L14.3438 14.504ZM24.9925 40.26L36.49 40.4421L45.4783 30.3478L48 33.3397L37.9381 44.2014H24.9925C24.9514 44.3566 24.9441 44.4568 24.9376 44.5463C24.9283 44.6743 24.9206 44.7806 24.8177 44.9949C23.5319 47.6746 20.3984 48.7673 17.8268 47.4275C15.2551 46.0876 14.2065 42.8226 15.4923 40.1429C16.7782 37.4633 19.9116 36.3706 22.4832 37.7104C23.4694 38.2177 24.5056 39.3234 24.9925 40.26ZM22.4707 30.6991H26.7152L29.1495 35.0828H34.3553L37.9131 30.6991L26.0536 9.35281H23.0949L11.3228 30.6991L14.9306 35.0828H19.974L22.4707 30.6991ZM15.2801 30.3218L24.4432 13.8406L33.7935 30.1397L31.459 32.195L28.5878 27.1348H20.4858L17.2525 32.195L15.2801 30.3218ZM20.723 25.3267C23.2822 24.4682 25.7664 24.5202 28.3381 25.3527C27.5687 24.1006 26.8252 22.8798 26.0847 21.6641C25.5636 20.8086 25.044 19.9555 24.5181 19.0959C23.2447 21.1772 22.0088 23.2064 20.723 25.3267Z"
              fill="url(#paint0_linear_343_136496)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_343_136496"
                x1="23.9938"
                y1="0"
                x2="23.9938"
                y2="47.9868"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#439CEF" />
                <stop offset="1" stop-color="#004EFF" />
              </linearGradient>
            </defs>
          </svg>
        </Link>
      </Flex>
    </React.StrictMode>
  );
});

const MenuListMobile = () => {
  const { closeSidebar, isSidebarOpen }: any = useMenuContext();
  const [isArr] = useState(ArrClone);
  return (
    <>
      {isSidebarOpen ? <OverplayMenu onClick={() => closeSidebar()} /> : <></>}
      <WrapperMobile isSidebarOpen={isSidebarOpen}>
        <Box
          width="100%"
          height="100%"
          className={`cus-modal ${isSidebarOpen ? "cus-showModal" : ""}`}
        >
          <WrapperMenuTop>
            <Link className="menu-top" to="/" onClick={() => closeSidebar()}>
              <MobileLogo />
              <span>AirPayOne</span>
            </Link>
            <IconButton
              className="bt-mobile icon-close"
              onClick={() => closeSidebar()}
              startIcon={<CloseConnectIcon width="30px" />}
            />
          </WrapperMenuTop>
          <div>
            <Flex className="menu-content">
              <Flex className="button-mobile" onClick={() => closeSidebar()}>
                <Connect />
              </Flex>
              <Flex
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                {isArr.map((_item, _index) => {
                  return (
                    <Box className="link-item">
                      {_item.title === "DOCS" ? (
                        <a
                          href={_item.href}
                          onClick={debounce(closeSidebar, 200)}
                          target="_blank"
                        >
                          <Text
                            fontFamily="PlayRegular"
                            textAlign="center"
                            fontWeight="400"
                            fontSize="5vw"
                            as="h1"
                          >
                            {_item.title}
                          </Text>
                        </a>
                      ) : (
                        <Link
                          to={_item.href}
                          onClick={debounce(closeSidebar, 200)}
                        >
                          <Text
                            fontFamily="PlayRegular"
                            textAlign="center"
                            fontWeight="400"
                            fontSize="5vw"
                            as="h1"
                          >
                            {_item.title}
                          </Text>
                        </Link>
                      )}
                    </Box>
                  );
                })}
              </Flex>
              <MenuFt>
                <Link to="/">
                  <IconButton
                    className="bt-mobile icon-social"
                    startIcon={<TwitterIcon />}
                  />
                </Link>
                <Link to="/">
                  <IconButton
                    className="bt-mobile icon-social"
                    startIcon={<DiscordIcon />}
                  />
                </Link>
                <Link to="/">
                  <IconButton
                    className="bt-mobile icon-social"
                    startIcon={<TelegramIcon />}
                  />
                </Link>
              </MenuFt>
            </Flex>
          </div>
        </Box>

        {/* <div className="blur">
        <h1>My Resume</h1>
      </div>
      <div className="blur">
        <h2>“Innovation distinguishes between a leader and a follower” ― Steve Jobs</h2>
      </div> */}
      </WrapperMobile>
    </>
  );
};

export default React.memo(MenuListMobile);

const OverplayMenu = styled.div`
  @media only screen and (min-width: 744px) and (max-width: 968px) {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
  }
`;

const WrapperMobile = styled.div<{ isSidebarOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transition: height 0.4s ease;
  height: ${({ isSidebarOpen }) => (isSidebarOpen ? "100%" : "0%")};
  background: linear-gradient(129deg, #032980 0%, #000 100%);
  z-index: 110;

  border-radius: 4px;
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);

  &::before {
    border-radius: 4px;
    backdrop-filter: blur(20px) saturate(180%);
    -webkit-backdrop-filter: blur(20px) saturate(180%);
  }

  .bt-mobile {
    background: transparent;
    height: auto;
    box-shadow: none;

    :hover:not(:disabled):not(.button--disabled):not(.button--disabled):not(
        :active
      ) {
      box-shadow: none;
    }
  }

  .cus-modal {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, visibility 0.4s ease;
  }

  .cus-showModal {
    opacity: 1;
    visibility: visible;
  }
  .menu-top {
    display: flex;
    align-items: center;
    span {
      font-family: PlayBold;
    }
  }
  .menu-content {
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow-y: auto;
  }
  .link-item {
    font-family: PlayRegular;
    text-align: center;
    font-weight: 400;
  }
  @media only screen and (min-width: 744px) and (max-width: 968px) {
    width: 382px;
    left: auto;
    .link-item {
      margin-bottom: 24px;
      h1 {
        font-size: 16px;
      }
    }
    .menu-content {
      padding-top: 24px;
      margin-right: -16px;
      padding-bottom: 100px;
    }
    .menu-top {
      span {
        margin-left: 16px;
        font-size: 24px;
      }
    }
    .button-mobile {
      button {
        max-width: 245px;
        width: 245px;
        max-height: 43px;
        margin-bottom: 46px;
      }
    }
    .icon-social {
      svg {
        width: 32px;
        height: 32px;
      }
    }
    .icon-close {
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
  @media only screen and (max-width: 743px) {
    .link-item {
      margin-bottom: 7.5vw;
      h1 {
        font-size: 5vw;
      }
    }
    .menu-content {
      padding-top: 6.25vw;
      margin-right: -5.6vw;
      padding-bottom: 31.25vw;
    }
    .menu-top {
      span {
        margin-left: 5vw;
        font-size: 7.5vw;
      }
    }
    .button-mobile {
      button {
        max-width: 76.5vw;
        width: 76.5vw;
        padding: 3.75vw 6.25vw;
        max-height: initial;
        height: auto;
        font-size: 5vw;
        margin-bottom: 14.3vw;
      }
    }
    .icon-social {
      svg {
        width: 9.4vw;
        height: 8.1vw;
      }
    }
    .icon-close {
      svg {
        width: 9.4vw;
        height: 8.1vw;
      }
    }
  }
`;

const WrapperMenuTop = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 24px 20px;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    background: radial-gradient(
      50% 180000% at 50% 50.01%,
      #0542cc 0%,
      rgba(4, 49, 153, 0) 100%
    );
  }
  @media only screen and (max-width: 743px) {
    min-height: 6.25vw;
    padding: 7.5vw 6.25vw;
  }
`;

const Heading = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  font-family: "PanchangBold";
  display: inline-block;
  background: linear-gradient(
    180deg,
    #e70303 13.39%,
    #8e2121 92.19%,
    #fff 100%,
    #fff 47%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
const HeadingV1 = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  font-family: Devator;
  display: inline-block;

  background: linear-gradient(119.25deg, #dfdfdf 11.11%, #909090 83.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const IconOrbit = styled.img`
  max-width: 100%;
  width: 42px;
  height: 100%;
  display: block;
`;

const MenuFt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  @media only screen and (min-width: 744px) and (max-width: 968px) {
    padding-top: 30px;
    gap: 8px;
  }
  @media only screen and (max-width: 743px) {
    padding-top: 12.5vw;
    gap: 2.5vw;
  }
`;
