import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Flex, Box } from "@raca2022/uikit";
import { LogoAirPayOne, LogoAirPayOneWhite } from "../../Svg";
interface LogoProps {
  href: string;
}

const StyledLink = styled(Link)`
  .desktop-sp {
    display: flex;
    align-items: flex-end;
    span {
      margin-left: 10px;
      font-size: 1.66vw;
      line-height: 18px;
      font-family: OrbitronBold;
    }
  }
  @media only screen and (min-width: 1440px) {
    .desktop-sp {
      span {
        font-size: 24px;
      }
    }
  }
  @media only screen and (min-width: 744px) and (max-width: 968px) {
    .desktop-sp {
      span {
        font-size: 24px;
      }
    }
  }
  @media only screen and (max-width: 743px) {
    .desktop-sp {
      span {
        display: none;
      }
      svg {
        width: 10.6vw;
        height: 9.3vw;
      }
    }
  }
`;

const Logo: React.FC<LogoProps> = ({ href }) => {
  const isAbsoluteUrl = href.startsWith("http");
  const location = useLocation();
  const innerLogo = (
    <>
      <Flex>
        {/* {location.pathname === "/" ? ( */}
        <Box className="desktop-sp">
          <LogoAirPayOne />
          <span>AirPayOne</span>
        </Box>
        {/* ) : ( */}
        {/* <Box>
            <LogoAirPayOneWhite />
          </Box> */}
        {/* )} */}
      </Flex>
    </>
  );

  return (
    <Flex>
      {isAbsoluteUrl ? (
        <StyledLink as="a" href={href} aria-label="">
          {innerLogo}
        </StyledLink>
      ) : (
        <StyledLink to={href} aria-label="">
          {innerLogo}
        </StyledLink>
      )}
    </Flex>
  );
};

export default React.memo(Logo);
