import React from "react";

const useJwt = () => {
  const hasSignedIn = window.localStorage.getItem("isCheck");
  const accountStatus = window.localStorage.getItem("accountStatus");
  const jwt = window.localStorage.getItem("jwt");

  return {
    hasSignedIn,
    accountStatus,
    jwt
  };
};

export default useJwt;
