import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 743px) {
    padding: 0 6.25vw;
  }
`;

export const BoxLayout = styled.div`
  position: relative;
  width: 420px;
  min-height: 367px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 16px rgba(1, 16, 51, 0.75);
  backdrop-filter: blur(8px);

  border: 1.5px solid ;
  border-image-source: linear-gradient(144.12deg, #004EFF 10.79%, #19010D 93.15%);
  border-radius: 16px;
  margin-bottom: 23px;
  @media only screen and (max-width: 743px) {
    width: 100%;
  }
`;

export const BoxSwapLayout = styled.div`
  position: relative;
  width: 100%;
  padding: 18px 24px 24px;
  div {
    :first-child {
      p {
        :first-child {
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            font-size: 14px;
            line-height: 20px;
            font-family: ClashDisplayMedium;
            :first-child {
              font-weight: 400;
            }
            :last-child {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 743px) {
    padding: 20px 10px;
    .change-icon {
      width: 22.5vw;
      height: 22.5vw;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
`;

export const BoxSelect = styled.div`
  position: relative;
  width: 100%;
`;

export const BoxInput = styled.input`
  background: #011033;
  border: 1px solid #23365e;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 48px;
  outline: none;
  width: 100%;
  color: #fff;
  padding-left: 28px;
  font-size: 24px;
  line-height: 24px;
  position: relative;
  padding-right: 41%;
  &#button-disabled {
    padding-right: 28%;
  }
  &:focus {
    background: #011033;
    font-size: 24px;
    line-height: 24px;
  }
  @media only screen and (max-width: 743px) {
    font-size: 6.25vw;
    height: 15vw;
    padding-left: 5vw;
    padding-right: 45.3vw;
    &#button-disabled {
      padding-right: 32.2vw;
    }
    &:focus {
      font-size: 20px;
    }
  }
`;

export const BoxIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  .view_max {
    font-family: ClashDisplay;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #ffffff;
    position: relative;
    padding-right: 8px;
    border-right: 1px solid #828282;
  }
  p {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #00aeff;
    border-right: 2px solid #02194d;
    transition: color .25s;
    cursor: pointer;
    &:hover {
      color: #e0eaef;
    }
  }
  .asc-icon {
    height: 26px;
    padding: 0 8px;
  }
  .asc-text {
    padding-right: 10px;
    font-family: PlayBold;
  }
  @media only screen and (max-width: 743px) {
    p {
      padding-top: .625vw;
      padding-bottom: .625vw;
      padding-right: 3.125vw;
      font-size: 5vw;
      border-width: .625vw;
    }
    .asc-icon {
      padding: 0 2.5vw;
      height: 8.125vw;
      img {
        width: 7.5vw;
        height: 7.5vw;
      }
    }
    .asc-text {
      font-size: 5vw;
      padding-right: 3.125vw;
    }
  }
`;

export const Des = styled.p`
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  P {
    font-size: 18px;
  }
  @media only screen and (max-width: 743px) {
    font-size: 5vw;
    p {
      font-size: 5.6vw;
    }
  }
`;

export const AutoRenew = styled.div`
  @media only screen and (max-width: 743px) {
    svg {
      width: 6.5vw;
      height: 5.6vw;
    }
  }
`

export const ButtonSwap = styled.button`
  width: 100%;
  height: 48px;
  background: linear-gradient(90deg, #004EFF 0%, #043199 101.16%);
  border: 1.5px solid #032980;
  border-radius: 4px;
  display: grid;
  place-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  transition: all .25s;
  &:hover {
    background: linear-gradient(89.92deg, #004EFF 0.06%, #0542CC 99.93%);
    box-shadow: 0px 0px 20px rgba(0, 78, 255, 0.5);
  }
  @media only screen and (max-width: 743px) {
    height: 15vw;
    font-size: 5.6vw;
  }
`;

export const BoxTotalLayout = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
