// start Login ==========>
export const LOGIN_REQUEST = "@user/LOGIN_REQUEST";
export const LOGIN_SUCCESS = "@user/LOGIN_SUCCESS";
export const LOGIN_FAILED = "@user/LOGIN_FAILED";
// end Login <==========

// start dashboard =========>
export const LIST_USER_REQUEST = "@user/LIST_USER_REQUEST";
export const LIST_USER_SUCCESS = "@user/LIST_USER_SUCCESS";
export const LIST_USER_FAILED = "@user/LIST_USER_FAILED";
// end dashboard <===========

// start wallet =========>ReceiveAddress
export const ReceiveAddress_WALLET_REQUEST = "@user/ReceiveAddress_WALLET_REQUEST";
export const ReceiveAddress_WALLET_SUCCESS = "@user/ReceiveAddress_WALLET_SUCCESS";
export const ReceiveAddress_ALLET_FAILED = "@user/ReceiveAddress_ALLET_FAILED";
// end wallet <===========

// start history =========>
export const LICHSUDAUTU_SUCCESS = "@user/LICHSUDAUTU_SUCCESS";
export const LICHSUDAUTU_REQUEST = "@user/LICHSUDAUTU_REQUEST";
export const LICHSUDAUTU_FAILED = "@user/LICHSUDAUTU_FAILED";
// end history  <===========

// start Affiliates get =========>
export const LIST_AFFILIATED_SUCCESS = "@user/LIST_AFFILIATED_SUCCESS";
export const LIST_AFFILIATED_FAILED = "@user/LIST_AFFILIATED_FAILED";
// end Affiliates get <===========

//start swap
export const SWAP_SUCCESS = "@user/SWAP_SUCCESS";
export const SWAP_FAILED = "@user/SWAP_FAILED";
//end swap

// start withdraw
// export const LICHSUGIAODICH_SUCCESS = "@user/LICHSUGIAODICH_SUCCESS";
export const WITHDRAW_SUCCESS = "@user/WITHDRAW_SUCCESS";
export const WITHDRAW_FAILED = "@user/WITHDRAW_FAILED";
export const WITHDRAW_FEE_SUCCESS = "@user/WITHDRAW_FEE_SUCCESS";
export const WITHDRAW_FEE_FAILED = "@user/WITHDRAW_FEE_FAILED";
// end withdraw

// ProductSanPham
export const PRODUCT_SP_SUCCESS = "@user/PRODUCT_SP_SUCCESS";
export const PRODUCT_SP_FAILED = "@user/PRODUCT_SP_FAILED";

// start get priceToken
export const LIST_PRICETOKEN_REQUEST = "@user/LIST_PRICETOKEN_REQUEST";
export const LIST_PRICETOKEN_SUCCESS = "@user/LIST_PRICETOKEN_SUCCESS";
export const LIST_PRICETOKEN_FAILED = "@user/LIST_PRICETOKEN_FAILED";

// investmentHistory
export const INVESTMENT_SUCCESS = "@user/INVESTMENT_SUCCESS";
export const INVESTMENT_FAILED = "@user/INVESTMENT_FAILED";

// refund to
export const REFUND_SUCCESS = "@user/REFUND_SUCCESS";
export const REFUND_FAILED = "@user/REFUND_FAILED";

//get fee
export const GET_FEE_SUCCESS = "@user/GET_FEE_SUCCESS";
export const GET_FEE_FAILED = "@user/GET_FEE_FAILED_FAILED";