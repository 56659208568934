import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const TwitterIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg id="twitter" viewBox="0 0 20 20" fill="none" {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.0003 3.0376C15.4121 3.3007 14.7785 3.4755 14.1168 3.55345C14.7947 3.14745 15.3132 2.50403 15.5607 1.73808C14.927 2.11278 14.2228 2.3877 13.4766 2.53473C12.8766 1.89723 12.0244 1.50098 11.0804 1.50098C9.26768 1.50098 7.80013 2.96938 7.80013 4.78093C7.80013 5.03663 7.82848 5.28673 7.88401 5.52798C5.15651 5.38978 2.73848 4.08405 1.11976 2.09743C0.828792 2.59863 0.675577 3.16789 0.675656 3.74743C0.675656 4.88485 1.25321 5.89088 2.13433 6.4776C1.59663 6.46165 1.09083 6.31165 0.646706 6.06863V6.10968C0.646706 7.70035 1.77733 9.02763 3.27971 9.32763C3.00451 9.40233 2.71396 9.44368 2.41456 9.44368C2.20256 9.44368 1.99821 9.42183 1.79506 9.38493C2.21463 10.6877 3.42588 11.6373 4.86036 11.6651C3.73563 12.5462 2.32303 13.0709 0.783106 13.0709C0.517356 13.0709 0.258406 13.0546 -0.000244141 13.0236C1.45548 13.9576 3.17991 14.4986 5.03251 14.4986C11.068 14.4986 14.3713 9.49683 14.3713 5.16005C14.3713 5.01595 14.3681 4.8763 14.3622 4.73515C15.0032 4.27658 15.5589 3.69783 15.9989 3.03905L16.0004 3.03758L16.0003 3.0376Z"
            fill="white"
          />
        <defs>
          <clipPath id="clip0_29_224">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Svg>
  );
};

export default TwitterIcon;
