import styled from "styled-components";
import { ButtonV1 } from "@raca2022/uikit";

export const MenuWrapper = styled.div<{ isScroll?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 34px 52px;
  /* background: rgba(28, 28, 28, 0.9); */
  z-index: 90;
  background: ${({ isScroll }) =>
    isScroll
      ? "linear-gradient(rgba(6, 6, 29,.9), rgba(6, 6, 29,.9), rgba(6, 6, 29,.9), rgba(6, 6, 29,.9), rgba(6, 6, 29,.9))"
      : "rgba(0,0,0,0)"};
  /* backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  border-radius: 4px;
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property: background-color, backdrop-filter, -webkit-backdrop-filter; */

  /* &::before {
    backdrop-filter: blur(20px) saturate(180%);
    -webkit-backdrop-filter: blur(20px) saturate(180%);
    border-radius: 4px;
  } */
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    background: radial-gradient(
      50% 180000% at 50% 50.01%,
      #0542cc 0%,
      rgba(4, 49, 153, 0) 100%
    );
  }
  /* .fl-left {
    flex-basis: 100%;
  } */

  /* @media only screen and (max-width: 1200px) {
    padding: 18px 14px;
    background: linear-gradient(
      #06061d,
      #06061d,
      #06061d,
      #06061d,
      transparent
    );
  } */

  @media only screen and (max-width: 968px) {
    padding: 20px 15px;
  }
`;

export const ButtonConect = styled(ButtonV1)`
  position: relative;
  max-width: 200px;
  max-height: 36px;
  background: linear-gradient(275.32deg, #004eff 0.5%, #0047e8 98.34%);
  font-weight: 500;
  border-radius: 4px;
  margin-left: auto;
  /* border:none;
  outline:none; */
  /* cursor: pointer; */
  transition: all 0.25s !important;
  text-shadow: none !important;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    margin: -2px;
    background-image: linear-gradient(
      265.98deg,
      #004eff -2.85%,
      #6bd0ff 101.88%
    );
    border-radius: 4px;
    transition: all 0.25s !important;
  }
  &:hover {
    max-width: 206px;
    opacity: 1 !important;
    font-weight: bold;
    background: linear-gradient(265.47deg, #004eff 0%, #0542cc 100%);
    box-shadow: 0px 0px 24px rgba(0, 78, 255, 0.75) !important;
    &::before {
      background: linear-gradient(265.47deg, #004eff 0%, #0542cc 100%);
    }
  }
`;

export const WrapperMobile = styled.div`
  .bt-mobile {
    background: transparent;
    height: auto;
    padding-right: 10px;
    box-shadow: none !important;
  }
  @media only screen and (max-width: 968px) {
    display: flex;
  }
`;

export const BoxMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuLangdingWapper = styled.div<{ isScroll?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 34px;
  z-index: 90;
  height: 90px;
  /* background: ${({ isScroll }) =>
    isScroll
      ? "linear-gradient(rgb(6, 6, 29), rgb(6, 6, 29), rgb(6, 6, 29), rgb(6, 6, 29), rgb(6, 6, 29), transparent)"
      : "rgba(0,0,0,0)"}; */
  @media only screen and (min-width: 1440px) {
    background: transparent;
  }
  @media only screen and (max-width: 1200px) {
    padding: 32px 51px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px 24px;
  }
  button {
    position: relative;
    width: 129px;
    height: 36px;
    background: linear-gradient(275.32deg, #004eff 0.5%, #0047e8 98.34%);
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.25s;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      margin: -2px;
      background-image: linear-gradient(
        265.98deg,
        #004eff -2.85%,
        #6bd0ff 101.88%
      );
      border-radius: 4px;
      transition: all 0.25s;
    }
    &:hover {
      width: 136px;
      font-weight: bold;
      background: linear-gradient(265.47deg, #004eff 0%, #0542cc 100%);
      box-shadow: 0px 0px 24px rgba(0, 78, 255, 0.75);
      &::before {
        background: linear-gradient(265.47deg, #004eff 0%, #0542cc 100%);
      }
    }
  }
  @media only screen and (min-width: 280px) and (max-width: 743px) {
    button {
      width: 34.68vw;
      height: 10vw;
      font-size: 4.3vw;
      &:hover {
        width: 37vw;
      }
    }
  }
`;
