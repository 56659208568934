import React, { useState } from "react";
import { Flex, Text } from "@raca2022/uikit";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ArrClone } from "./config";

const MenuList = () => {
  const [isArr] = useState(ArrClone);

  return (
    <WrapperList>
      {isArr.map((_item, _index) => {
        return (
          <>
            {_item.title === "DOCS" ? (
              <a href={_item.href} target="_blank">
                <Flex>
                  <Item as="h1">{_item.title}</Item>
                </Flex>
              </a>
            ) : (
              <NavLink to={_item.href}>
                <Flex>
                  <Item as="h1">{_item.title}</Item>
                </Flex>
              </NavLink>
            )}
          </>
        );
      })}
    </WrapperList>
  );
};

export default React.memo(MenuList);

const WrapperList = styled(Flex)`
  gap: 32px;
  margin-left: auto;
  justify-content: center;

  .activeIcons {
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms ease, visibility 500ms ease;
    animation: spinss 10s linear infinite;

    @-moz-keyframes spinss {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spinss {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spinss {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  a.active {
    h1 {
      color: #00D1FF !important;
      text-decoration: underline;
      font-weight: bold;
    }

    .activeIcons {
      opacity: 1;
      visibility: visible;
    }
  }
  @media only screen and (min-width: 1440px) {
    padding-left: 0;
  }
`;

const Item = styled(Text)`
  font-family: PlayRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: #ffffff;
  cursor: url("/assets/cursor/cursor.svg") 15 15, move !important;
  transition: color 0.2s ease;

  :hover {
    color: #00D1FF;
    font-weight: bold;
  }

  @media only screen and (max-width: 1200px) {
    font-size: 1.35vw;
  }
`;
