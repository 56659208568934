import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const DiscordIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg id="discord" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M16.9308 1.32631C15.6561 0.712422 14.2892 0.260132 12.8599 0.00108894C12.8339 -0.00391749 12.8079 0.0085829 12.7945 0.0335626C12.6187 0.361741 12.4239 0.78988 12.2876 1.12639C10.7503 0.884844 9.22099 0.884844 7.71527 1.12639C7.57887 0.782396 7.37707 0.361741 7.20048 0.0335626C7.18707 0.00941205 7.16107 -0.00307784 7.13504 0.00108894C5.70659 0.259303 4.33963 0.711593 3.06411 1.32631C3.05307 1.33131 3.04361 1.33964 3.03732 1.35046C0.444493 5.4161 -0.265792 9.38175 0.0826501 13.2983C0.0842267 13.3175 0.0944749 13.3358 0.108665 13.3474C1.81934 14.666 3.47642 15.4665 5.10273 15.9971C5.12876 16.0055 5.15634 15.9954 5.1729 15.9729C5.55761 15.4216 5.90054 14.8401 6.19456 14.2288C6.21192 14.193 6.19535 14.1505 6.15989 14.1363C5.61594 13.9198 5.098 13.6557 4.59977 13.3558C4.56037 13.3317 4.55721 13.2725 4.59347 13.2442C4.69831 13.1618 4.80318 13.0759 4.9033 12.9893C4.92141 12.9735 4.94665 12.9701 4.96794 12.9801C8.24107 14.5486 11.7846 14.5486 15.0191 12.9801C15.0404 12.9693 15.0657 12.9726 15.0846 12.9885C15.1847 13.0751 15.2895 13.1618 15.3952 13.2442C15.4314 13.2725 15.4291 13.3317 15.3897 13.3558C14.8914 13.6615 14.3735 13.9198 13.8288 14.1354C13.7933 14.1496 13.7775 14.193 13.7949 14.2288C14.0952 14.8393 14.4381 15.4206 14.8157 15.9721C14.8315 15.9954 14.8599 16.0055 14.8859 15.9971C16.5201 15.4665 18.1772 14.666 19.8879 13.3474C19.9028 13.3358 19.9123 13.3184 19.9139 13.2992C20.3309 8.77122 19.2154 4.83803 16.9568 1.35129C16.9513 1.33964 16.9419 1.33131 16.9308 1.32631ZM6.68335 10.9136C5.69792 10.9136 4.88594 9.96405 4.88594 8.79788C4.88594 7.63175 5.68217 6.68221 6.68335 6.68221C7.69239 6.68221 8.49651 7.64008 8.48073 8.79788C8.48073 9.96405 7.68451 10.9136 6.68335 10.9136ZM13.329 10.9136C12.3435 10.9136 11.5316 9.96405 11.5316 8.79788C11.5316 7.63175 12.3278 6.68221 13.329 6.68221C14.338 6.68221 15.1421 7.64008 15.1264 8.79788C15.1264 9.96405 14.338 10.9136 13.329 10.9136Z"
        fill="white"
      />
    </Svg>
  );
};

export default DiscordIcon;
