import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 9 16" fill="none" {...props}>
      <svg
        width="9"
        height="16"
        viewBox="0 0 9 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.292673 15.7661C0.616605 16.078 1.14103 16.078 1.46414 15.7661L8.31474 9.15558C8.96178 8.53119 8.96178 7.51825 8.31474 6.89386L1.41443 0.234076C1.09381 -0.0745251 0.576015 -0.0787283 0.251255 0.225873C-0.0801325 0.536865 -0.0842686 1.04954 0.242148 1.36533L6.55756 7.4591C6.88149 7.77169 6.88149 8.27776 6.55756 8.59035L0.292673 14.6357C-0.0312579 14.9475 -0.0312579 15.4544 0.292673 15.7661Z"
          fill="white"
        />
      </svg>
    </Svg>
  );
};

export default Icon;
