import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const TeleIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg id="telegram" viewBox="0 0 16 16" fill="none" {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
          <path
            d="M14.9772 1.10482L0.743868 6.41583C-0.216662 6.75304 -0.216662 7.34315 0.569226 7.51176L4.14939 8.60768L5.54652 12.6542C5.72116 13.0757 5.63384 13.2443 6.07045 13.2443C6.41973 13.2443 6.59437 13.0757 6.76902 12.9071C6.85634 12.8228 7.64223 12.0641 8.51544 11.221L12.1829 13.8344C12.8815 14.1716 13.3181 14.003 13.4927 13.2443L15.9377 2.20074C16.1997 1.27342 15.5884 0.767608 14.9772 1.10482ZM13.1434 3.63387L6.33241 9.6193L6.07045 12.4013L4.67331 8.35477L12.7068 3.46527C13.0561 3.21236 13.4054 3.38097 13.1434 3.63387Z"
            fill="white"
          />
        <defs>
          <clipPath id="clip0_190_19706">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Svg>
  );
};

export default TeleIcon;
